<template>
  <div class="evaluate">
    <div class="df-row">
      <div class="df-col">
        <el-checkbox
          v-model="submitData.Evaluate"
          :true-label="1"
          :false-label="0"
          :checked="submitData.Evaluate ? true : false"
          label="开启结账后评论提醒"
          @change=""
        ></el-checkbox>
      </div>
      <div class="df-col evaluate-time">
        结账
        <el-input
          v-model="submitData.EvaluateTime"
          size="mini"
          clearable
          @change=""
        ></el-input>
        分钟后,发送评价通知顾客
      </div>
    </div>

    <div class="df-row">
      <div class="df-col">
        <span class="title">参与评价职位</span>
        <div class="evaluate-box">
          <div
            class="df-row"
            v-for="(item, index) in submitData.EvaluateExtension.DutyUsages"
            :key="index"
          >
            <div class="df-col">
              <span>职位：</span>

              <el-select v-model="item.DutyNo" size="mini" @change="">
                <el-option
                  v-for="item in allDutyList"
                  :key="item.DutyNo"
                  :label="item.DutyName"
                  :value="item.DutyNo"
                >
                </el-option>
              </el-select>
            </div>

            <div class="df-col">
              <span>别称：</span>

              <el-input
                v-model="item.DutyAlias"
                size="mini"
                clearable
                @change=""
              ></el-input>
            </div>

            <div class="df-col icon-box">
              <i
                class="el-icon-remove remove-icon"
                @click="onRemoveAlias(index)"
              ></i>
              <i
                v-show="!index"
                class="el-icon-circle-plus add-icon"
                @click="onAddAlias"
              ></i>
            </div>
          </div>

          <!-- <el-button class="add-btn" type="primary" plain size="mini"
            >新增</el-button
          > -->
        </div>
      </div>
    </div>

    <div class="df-row">
      <div class="df-col evaluate-content">
        <span class="title">评价推送内容</span>
        <el-input
          v-model="submitData.EvaluateContent"
          size="small"
          clearable
        ></el-input>
      </div>
    </div>

    <div class="df-row">
      <div class="df-col">
        <el-button
          type="primary"
          size="small"
          :disabled="submitDisabled"
          @click="saveEvaluate"
          >保存</el-button
        >
      </div>
    </div>

    <!-- <el-form label-width="100px" :inline="false" size="small">
      <el-form-item label="评价推送内容"> </el-form-item>
      <el-form-item label="别称设置"> </el-form-item>
      <el-form-item>
      </el-form-item>
    </el-form> -->
  </div>
</template>

<script>
import Setting from "@/api/setting.js";

export default {
  data() {
    return {
      allDutyList: [], // 职位列表
      submitData: {
        // 提交数据
        EvaluateExtension: {
          DutyUsages: [],
        },
      },
      submitDisabled: false, // 保存防抖
    };
  },

  mounted() {
    this.initEvaluate();
  },

  methods: {
    // 初始化评价数据
    async initEvaluate() {
      try {
        let { data } = await Setting.initEvaluate();
        let { AllDutyList, ...submitData } = data;

        this.allDutyList = AllDutyList;
        this.submitData = submitData;
        submitData.EvaluateExtension.DutyUsages.length>0 ? '' : this.onAddAlias()
      } catch (err) {
        console.log(err);
      }
    },

    // 保存评价数据
    async saveEvaluate() {
      this.submitDisabled = true;
      let DutyUsages = await this.handleDutyUsages();
      if (DutyUsages == false) {
        return (this.submitDisabled = false);
      }

      try {
        let submitData = {
          ...this.submitData,
          EvaluateExtension: {
            DutyUsages,
          },
        };

        let { errcode } = await Setting.saveEvaluate(submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.initEvaluate();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 处理职位别称数据
    async handleDutyUsages() {
      let arr = [];
      let dutyUsages = JSON.parse(
        JSON.stringify(this.submitData.EvaluateExtension.DutyUsages)
      );
      //   console.log(dutyUsages);
      for (let i = 0; i < dutyUsages.length; i++) {
        if (!dutyUsages[i].DutyNo && dutyUsages[i].DutyAlias) {
          await this.$message.error("请选择职位");
          return false;
        } else if (dutyUsages[i].DutyNo && !dutyUsages[i].DutyAlias) {
          await this.$message.error("请输入别称");
          return false;
        } else if (dutyUsages[i].DutyNo && dutyUsages[i].DutyAlias) {
          await arr.push(dutyUsages[i]);
        }
      }

      //   await this.$nextTick(() => {
      return arr;
      //   });
    },

    // 添加一条别称设置
    onAddAlias() {
      this.submitData.EvaluateExtension.DutyUsages.push({
        DutyAlias: "",
        DutyNo: "",
      });
    },

    // 移除一条别称设置
    onRemoveAlias(index) {
      this.submitData.EvaluateExtension.DutyUsages.splice(index, 1);
      if (!this.submitData.EvaluateExtension.DutyUsages.length) {
        this.onAddAlias();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.evaluate {
  > .df-row {
    align-items: center;
    margin-top: 20px;

    > .df-col {
      .flex-col;
      align-items: unset;
      .title {
        margin-bottom: 6px;
      }

      .evaluate-box {
        width: 700px;
        height: auto;
        padding: 20px;
        padding-bottom: 0;
        box-sizing: border-box;
        border: 1px solid #ddd;

        > .df-row {
          > .df-col {
            .flex-row;

            span {
              flex: 1;
            }

            .el-select{
              width: 200px;
            }

            .el-input {
              width: 120px;
            }
          }

          .icon-box {
            .flex-row;
            align-items: center;
            justify-content: center;

            i {
              margin: 0 3px;
              font-size: 20px;
              cursor: pointer;
            }
            .remove-icon {
              color: #f56c6c;
            }

            .add-icon {
              color: #67c23a;
            }
          }
        }

        // .add-btn {
        //   width: 40%;
        //   margin: 0 auto;
        // }
      }
    }

    .evaluate-time {
      .flex-row;
      margin-left: 20px;
      transform: translateY(-2px);
      .el-input {
        width: 80px;
      }
    }

    .evaluate-content .el-input {
      width: 700px;
    }
  }
}
</style>
